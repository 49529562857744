import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import "./i18next";
import App from './App';
import * as serviceWorker from './serviceWorker';

import {icons} from './assets/icons'

import {Provider} from 'react-redux'
import store from './store'

React.icons = icons;


ReactDOM.render(
  <Suspense>
    <Provider store={store}>
      <App/>
    </Provider>
  </Suspense>,
  document.getElementById('root')
);



serviceWorker.unregister();
