import React, {useEffect, useRef, useState} from 'react'
import {useTranslation, withTranslation} from "react-i18next";
import IMaskInput from "react-imask/esm/input";
import 'antd/dist/antd.css';
import jwt_decode from 'jwt-decode'
import {useHistory, withRouter} from "react-router-dom";
import Button from "antd/es/button";
import {axiosWithRefreshPolicy, errorCodeModal} from "../../../assets/js/StringTool";
import {logger} from "../../../assets/js/Log";
import axiosClient from "../../../AxiosApi";

const errorField = {
  borderBottom: "1px solid red"
};


const LoginAsan = () => {


  const [loading, setLoading] = useState(false)
  const [requestCancelled, setRequestCancelled] = useState(false)
  const {t, i18n} = useTranslation()
  const history = useHistory()
  const [userIdVal, setUserId] = useState('')
  const [phoneNumberVal, setPhoneNumber] = useState('')
  const [initLoginResponse, setInitLoginResponse] = useState(null)
  const [phoneNumberEmpty, setPhoneNumberEmpty] = useState(false)
  const [userIdEmpty, setUserIdEmpty] = useState(false)

  useEffect(() => {
      document.title = t("login.title")
      if(!localStorage.init)  {
        i18n.changeLanguage("aze")
        localStorage.init = new Date().getUTCDate()
      }
    }
    , []
  )


  const completeLogin = async (phoneNumber, authData) => {
    if(requestCancelled) {
      return;
    }
    let completeResult
    try {
      completeResult = await axiosWithRefreshPolicy.post(`api-auth/auth/login/complete`, {
        phoneNumber, ...authData
      });
    } catch (e) {
      errorCodeModal(t, e);
      setInitLoginResponse(null)
      setLoading(false)

      return;
    }
    setLoading(false)
    const res = completeResult?.data?.data
    setInitLoginResponse(null)

    if(!res || !res.j || !res.r) return;
    const token = res.j
    const decodedJWT = jwt_decode(token, {header: false});

    const userDetails = decodedJWT.userDetails;


    localStorage.setItem("token", token);
    localStorage.setItem("r", res.r);
    localStorage.setItem("userDetails", JSON.stringify(userDetails));
    localStorage.setItem("roles", userDetails.roles.join(","));

    history.push("/");


  }

  const login = async () => {
    const phoneNumber = `+${phoneNumberVal}`
    const userId = userIdVal.replace(/[\- ]/g, "")

    console.log(phoneNumber, userId)
    setPhoneNumberEmpty(!phoneNumberVal)
    setUserIdEmpty(!userIdVal)

    if (!phoneNumber || !userId) {
      return;
    }

    setRequestCancelled(false)
    setLoading(true)
    let authResult
    logger({
      phoneNumber,
      userId,
      lang: i18n.language
    })
    try {
      authResult = await axiosClient.post(`api-auth/auth/login/init`, {
        phoneNumber,
        userId,
        lang: i18n.language
      });
    } catch (e) {

      errorCodeModal(t, e);
      setInitLoginResponse(null)
      setLoading(false)
      return;
    }
    setInitLoginResponse(authResult?.data?.data)
    completeLogin(phoneNumber, authResult?.data?.data)

  }

  function cancelLogin() {
    setInitLoginResponse(null)
    setRequestCancelled(true)
  }

  function inputKeyPress(e) {
    if (e.key === "Enter") login();
  }


  return (
    <div className="login-page">
      <div className="background">
        <img src="backgrounds/login_back.svg"/>
      </div>
      {
        !(initLoginResponse?.verificationCode) ?
          (<div className="login-card-holder">
            <div className="login-card">
              <div className="logo-holder">
                <img src="logos/asan_logo.png" alt=""/>
                <img src={`logos/taxes_logo_${i18n.language}.png`} alt=""/>
              </div>
              <div className="title-holder">
                <span>{t("login.login_page")}</span>
              </div>
              <div className="login-inputs-holder">
                <div style={phoneNumberEmpty ? errorField : {}}>
                  <img src="logos/asanIcon.svg" alt=""/>
                  <IMaskInput
                    mask='+{994} 00 000-00-00'
                    unmask={true} // true|false|'typed'
                    value={phoneNumberVal}
                    onAccept={(val) => setPhoneNumber(val)}
                    placeholder={t('login.phone_number')}
                    onKeyPress={(ev) => inputKeyPress(ev)}
                  />
                </div>
                <div style={userIdEmpty ? errorField : {}}>
                  <img src="logos/lockIcon.svg" alt=""/>
                  <IMaskInput
                    mask="00 00 00"
                    value={userIdVal}
                    onAccept={(val) => setUserId(val)}
                    placeholder={t('login.user_id')}
                    onKeyPress={(ev) => inputKeyPress(ev)}
                  />
                </div>
              </div>


              <div className="error-holder"
                   style={{visibility: (phoneNumberEmpty || userIdEmpty) ? "visible" : "hidden"}}>
                <span>{t("login.fill_all_fields")}</span>
              </div>


              <div className="button-section">
                <Button loading={loading} className="btn btn-info" onClick={() => login()}>
                  {t('login.button_login')}
                </Button>
              </div>
            </div>
          </div>) :
          (
            <div className="login-card-holder">
              <div className="login-card">

                <div className="logo-holder">
                  <img src="logos/asanLogo.svg" alt=""/>
                </div>
                <div className="desc-holder">
                  <div>
                    <span>{t("login.login_description_pin")}</span>
                    <br/>
                    <span>{t("login.login_verification_code_pin")}:</span>
                    <br/>
                    <span className="pin">{initLoginResponse?.verificationCode}</span>
                  </div>
                </div>

                <div className="button-section">
                  <button className="btn btn-info loading-button" onClick={() => cancelLogin()}>
                    {t('login.button_cancel')}
                  </button>
                </div>

              </div>
            </div>
          )
      }
    </div>
  )

}

export default withRouter(LoginAsan)
