
export const FieldType = {
  TITLE:"TITLE",
  SELECT:"SELECT",
  INPUT:"INPUT",
  ROW:"ROW"
};

export const CertTypes = {
  PERSONAL_SIGN: "PERSONAL_SIGN",
  BUSINESS_SIGN: "BUSINESS_SIGN",
  GOVERNMENT_SIGN: "GOVERNMENT_SIGN",
};


export const appLog = process.env.REACT_APP_LOG === 'true';
