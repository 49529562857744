import * as axios from "axios";
import MessageModal from "./assets/js/MessageModal";
import {logger} from "./assets/js/Log";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

axiosClient.interceptors.request.use(function (config) {
    // Do something before request is sent

    const allowedUrls = ['api-auth']

    if ((!localStorage.token || !localStorage.r) &&
      !allowedUrls.map(it => config.url.startsWith(it)).reduce((a, b) => a || b, false)
    ) {
      throw new Error("0001");
    }


    return config;
  }, function (error) {
    // Do something with request error
    logger(error);

    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(res => {
  if (res?.data?.status === 0) {
    throw new Error(res.data.error.code);
  }
  if (res.data.message) {
    throw new ValidationError(res.data.message, res.data);
  }
  return res;
}, error => {

  logger(error)
  if (!error?.response) {
    throw new Error("0050");
  }

  if (401 === error.response.status) {
    throw new Error("9999");
  } else {
    return Promise.reject(error);
  }
});

class ValidationError extends Error {
  constructor(code, response) {
    super(code); // (1)
    this.response = response; // (2)
  }
}


export default axiosClient;


