import moment from "moment";
import jwt_decode from "jwt-decode";
import MessageModal from "./MessageModal";
import axiosClient from "../../AxiosApi";
import {logger} from "./Log";
import {Loading3QuartersOutlined, LoadingOutlined} from "@ant-design/icons";
import React from "react";


export default class StringTool {

  static lastRefresh = 2;

  static create_UUID() {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  static getCurDate({deltaMonths = 0, deltaDays = 0, format = "YYYY-MM-DD"}) {
    let curMoment = moment();
    curMoment = curMoment.subtract(deltaMonths, 'months');
    // curMoment = curMoment.subtract(deltaDays, 'days');
    return curMoment.format(format);
  }

  static formatNumber(data) {
    if (!data) return "";
    if (data.length < 9) return data;
    return `${data.substr(0, 4)} ${data.substr(4, 2)} ${data.substr(6, 3)} ${data.substr(9, 2)} ${data.substr(11, 2)}`;
  }

}

export function wait(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      console.log("Done waiting");
      resolve(ms)
    }, ms)
  })
}

export function getPathParam(loccation, name) {
  return new URLSearchParams(loccation.search).get(name);
}


export function dateFormat(date) {
  if (!date) return "";
  if (typeof date !== "string") return date;
  const splitted = date.split(" ");
  const leftSide = splitted[0];
  const dateArr = leftSide.split("-");
  return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`
}

export function dateFormatHHMM(date) {
  if (!date) return "";
  const splitted = date.split(" ");
  if (splitted.length < 2) return dateFormat(date);
  const dateArr = splitted[0].split("-");
  const hoursArr = splitted[1].split(":");
  return `${dateArr[2]}-${dateArr[1]}-${dateArr[0]} ${hoursArr[0]}:${hoursArr[1]}`
}


export function getUserDetails() {
  const token = localStorage.token;
  let decodedJWT;
  try {
    decodedJWT = jwt_decode(token, {header: false});
  } catch (e) {
    localStorage.removeItem("token");
    localStorage.removeItem("r");
    localStorage.removeItem("roles");
    localStorage.removeItem("userDetails");
    window.location = '/login';
    return {};
  }
  if (decodedJWT.userDetails.fin.length === 8) {
    decodedJWT.userDetails.roles.push("ROLE_USER_VIN");
  }
  logger(decodedJWT.userDetails);
  return decodedJWT.userDetails;
}


export function wrapDocumentInHTML(docment, additionalStyle = "") {
  return `<html>
<head>
    <meta charset="UTF-8">
    <title>Müqavilə</title>
    <style>
    ${additionalStyle}
    </style>
</head>
<body>${docment}</body>
</html>`
}

export function errorCodeModal(t, e, callback = (() => {
})) {
  logger(e);
  console.log('ERROR>>', e);
  if (/^[0-9]+$/g.test(e.message)) {
    MessageModal.errorStatic(t("errors.error_title"), t(`errors.error_${e.message}`), callback);
  } else {
    MessageModal.errorStatic(t("errors.error_title"), t(`errors.error_1000`), callback);
  }
}

//Axios request with automatic token refresh if expired
export async function axiosWithRefreshPolicyRequest(method = "get", url = "", params = {}, optional = {}, additional = {} /*forceNoRefresh, noErrors*/) {
  logger("Making a request with params: ", {method, url, params, optional,});
  let result;
  const optionalParams = {
    headers: {
      "X-Auth-Token": localStorage.token,
    },
    params: params.params || {},
    ...optional
  };
  logger(optionalParams);

  const argParams = [];

  argParams.push(url);
  if(method === "delete") {
    argParams.push({params:params, headers:optionalParams.headers});
  } else if (method === "get") {
    argParams.push(optionalParams);
  } else {
    argParams.push(params);
    argParams.push(optionalParams);
  }


  try {
    result = await axiosClient[method](...argParams);
  } catch (e) {
    if (additional.noErrors) return;

    if (e.message === "9999") {
      if (additional.forceNoRefresh) {
        localStorage.removeItem("token");
        localStorage.removeItem("r");
        localStorage.removeItem("roles");
        localStorage.removeItem("userDetails");
        window.location.href = "/login";
        [].sort()
        return;
      }
      if (await refreshToken(url, params)) {
        return await axiosWithRefreshPolicyRequest(method, url, params, optional)
      } else {
        localStorage.removeItem("token");
        localStorage.removeItem("r");
        localStorage.removeItem("roles");
        localStorage.removeItem("userDetails");
        window.location.href = "/login";
        return;
      }
    }
    throw new Error(e.message);
  }
  return result;
}

export class axiosWithRefreshPolicy {

  static async get(...args) {
    return await axiosWithRefreshPolicyRequest("get", ...args);
  }

  static async put(...args) {
    return await axiosWithRefreshPolicyRequest("put", ...args);
  }

  static async post(...args) {
    return await axiosWithRefreshPolicyRequest("post", ...args);
  }

  static async delete(...args) {
    return await axiosWithRefreshPolicyRequest("delete", ...args);
  }

  static async patch(...args) {
    return await axiosWithRefreshPolicyRequest("patch", ...args);
  }

  static async general(paramObj) {
    return await axiosWithRefreshPolicyRequest(paramObj.method, paramObj.url, paramObj.body || {}, {responseType: paramObj.responseType});
  }
}


export async function refreshToken() {
  let res;
  const refreshDelta = 2000;
  const lastRefresh = StringTool.lastRefresh || refreshDelta;

  if (Date.now() - lastRefresh < refreshDelta) {
    await wait(3000);
    return true;
  }

  StringTool.lastRefresh = Date.now();
  logger("Refreshing token... ");
  try {
    res = await axiosClient.put("/api-auth/auth/refresh", {}, {headers: {refresh: localStorage.r}});
  } catch (e) {
    logger("Refreshing token failed");
    if (e.message === "9999") {
      delete localStorage.token;
      delete localStorage.r;
    }
    return false;
  }
  const {token, r} = res.data.data;
  localStorage.token = token;
  localStorage.r = r;
  return true;
}

export const preLoader = (props) => (
  <div className="pre-loader-init">
    <div className={"pre-loader-inner"}>
      <div className={"rotator"}>
        <LoadingOutlined/>
        <LoadingOutlined/>
        <LoadingOutlined/>
      </div>
    </div>
  </div>
);
