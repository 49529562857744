import React, {Component} from 'react';
import {BrowserRouter, Route, Switch, withRouter, Redirect} from 'react-router-dom';
import './scss/style.scss';
import LoginAsan from "./views/pages/login/LoginAsan";
import {Loading3QuartersOutlined} from "@ant-design/icons";
import {preLoader} from "./assets/js/StringTool";

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page401 = React.lazy(() => import('./views/pages/page401/Page401'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends React.Component {

  render() {
    return (
      <BrowserRouter>
        {/*{preLoader()}*/}
        <React.Suspense fallback={preLoader()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <LoginAsan {...props}/>}/>
            <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>}/>
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
            <Route exact path="/401" name="Page 401" render={props => <Page401 {...props} redirect={true}/>}/>
            <Route exact path="/loader" name="home" render={props => preLoader()}/>
            <Route path="/" name="Home" render={props => <TheLayout {...props}/>}/>
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
