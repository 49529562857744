import '../css/MessageModal.scss'
import {logger} from "./Log";


const createSpan = (...classes) => {
  const span = document.createElement("span");
  classes.forEach(className => span.classList.add(className));
  return span;
};
const createDiv = (...classes) => {
  const div = document.createElement("div");
  classes.forEach(className => div.classList.add(className));
  return div;
};


export default class MessageModal {
  static get OPTIONZ() {
    return {
      content: "Predefine contents of the modal",
      absolute: "Used to absolute position the modal",
      modalId: "Specific id for the modal",
      onClose: "Callback invoked when modal closed",
      noBack: "No background",
      noBackClose: "Prevent closing on outside clicks",
      noClose: "No 'x' closer"
    };
  }

  constructor(options = {}) {
    this.options = options;
    this.modalHolder = document.body.appendChild(createDiv("modal-tool"));
    if (!!this.options["modalId"]) this.modalHolder.id = this.options["modalId"];
    this.modalBack = this.modalHolder.appendChild(createDiv());
    this.modalBody = this.modalHolder.appendChild(createDiv());
    const closerContainer = this.modalBody.appendChild(createDiv("modal-close-button-container"));
    if (!this.options.noClose) {
      this.closeButton = closerContainer.appendChild(createSpan());
      this.closeButton.innerHTML = "x";
      this.closeButton.onclick = () => this.destroy();
    }
    if (options.hasOwnProperty("absolute") && !!options.absolute) {
      const marg = (window.innerHeight / 10).px();
      this.modalHolder.style.overflowY = "overlay";
      this.modalBody.style.wordBreak = "break-word";
      this.modalBody.style.position = "absolute";
      this.modalBody.style.top = marg;
      this.modalBody.style.marginBottom = marg;
    }
    if (options.hasOwnProperty("onClose")) {
      this.onClose = () => options.onClose();
    } else {
      this.onClose = () => {
      };
    }

    if (options.hasOwnProperty("content")) {
      const cont = createDiv();
      this.modalBody.appendChild(cont);
      cont.outerHTML = options.content;
    }
    this.modalBack.onclick = () => !!this.options["noBackClose"] === true ? {} : this.destroy();
    if (this.options["noBack"] === true) this.modalBack.style.background = "none";
    return this;
  }

  getBody() {
    return this.modalBody;
  };

  getHolder() {
    return this.modalHolder;
  }

  setCustomBackClass(clazz) {
    this.modalBack.classList.add(clazz);
  }

  switchAbsolute(bool) {
    if (bool) {
      const marg = (window.innerHeight / 10).px();
      this.modalHolder.style.overflowY = "overlay";
      this.modalBody.style.wordBreak = "break-word";
      this.modalBody.style.position = "absolute";
      this.modalBody.style.top = marg;
      this.modalBody.style.marginBottom = marg;
    } else {
      this.modalHolder.style.overflowY = "overlay";
      this.modalBody.style.wordBreak = "break-word";
      this.modalBody.style.position = "fixed";
    }
  }

  destroy() {
    if (!!this.options["noAnimation"]) {
      document.body.removeChild(this.modalHolder);
    } else {
      this.modalBody.style.animation = "bounceInDownCR 100ms forwards ease";
      setTimeout(() => {
        document.body.removeChild(this.modalHolder);
      }, 100);
    }

    this.onClose();
  };

  appendWarningTitle(title) {
    const titleSpan = this.modalBody.appendChild(createSpan("warn-title"));
    titleSpan.innerHTML = "<i class='fa fa-exclamation-circle'></i> " + title;
    return titleSpan;
  }

  appendCustomTitle(text, icon = "<i class=\"fas fa-envelope-open\"></i> ", clazz = "normal-title") {
    const titleSpan = this.modalBody.appendChild(createSpan(clazz));
    titleSpan.innerHTML = `${icon} ` + text;
    return titleSpan;
  }

  appendNeutralTitle(title, icon = "<i class=\"fas fa-envelope-open\"></i> ") {
    const titleSpan = this.modalBody.appendChild(createSpan("neutral-custom-title"));
    titleSpan.innerHTML = `${icon} ` + title;
    return titleSpan;
  }

  appendTitle(title) {
    const titleSpan = this.modalBody.appendChild(createSpan("normal-title"));
    titleSpan.innerHTML = "<i class='fas fa-envelope-square'></i> " + title;
    return titleSpan;
  }

  appendPadding(height = 50) {
    const padding = this.modalBody.appendChild(createSpan("modal-pad-px"));
    padding.style.height = `${height}px`
  }

  appendText(text) {
    const textSpan = this.modalBody.appendChild(createSpan("modal-tool-text"));
    textSpan.innerHTML = text;
    return textSpan;
  }

  appendFullContainer(content = "", padding = "10px") {
    const divEl = createDiv();
    divEl.style.height = "100%";
    divEl.style.width = "100%";
    divEl.style.padding = padding;
    divEl.innerHTML = content;
    this.modalBody.appendChild(divEl);
    return divEl;
  }

  static errorStatic(title="error_title", toLog, callback = () => {}) {
    const modal = new MessageModal();
    modal.appendWarningTitle(title);
    modal.appendText(toLog);
    modal.appendPadding();
    modal.onClose = callback;
    logger(title, toLog);
  }
  static errorT(t,e) {
    console.log('ERROR>>', e);
    MessageModal.errorStatic(t("errors.error_title"), t(`errors.error_${e.message}`));
  }
  static messageStatic(title="message", toLog, callback = () => {}) {
    const modal = new MessageModal();
    modal.appendNeutralTitle(title);
    modal.appendText(toLog);
    modal.appendPadding();
    modal.onClose = callback;
  }

}
